<template>
    <!-- 改了 -->
    <div class="slide-box">
        <template v-if="spShow">
            <Swiper :options="swiperOption" ref="mySwiper">
                <Swiper-slide v-for="(slide, index) in swiperVerticalData" :key="slide.id" class="boxzb-right-list" >
                    <div class="content" @click.stop="$emit('toteamPage',slide)">{{ slide.name }}</div>
                    <div class="time">{{ slide.createTime.substring(5,10) }}</div>
                </Swiper-slide>
            </Swiper>

            <!-- <div :class="pClass + 'swiperPagination'" class="swiperPagination">
            </div> -->
            <div class="boxzb-right-btn">
                <div class="line"></div>
            </div>
            <!-- 左右按钮横向位于底部 -->
            <div class="swiper-button-prev" slot="button-prev" id="button-prev"></div>
            <div class="swiper-button-next" slot="button-next" id="button-next"></div>
        </template>
        <template v-else>
            <van-empty description="暂无更多信息~" />
        </template>
    </div>

</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
    props: {
        swiperVerticalData: {
            type: Array,
            default: []
        },
        pClass: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            // 配置项含义，参考文档swiper3
            swiperOption: {
                notNextTick: true,
                direction: 'vertical',
                setWrapperSize: true,
                freeMode: true,//true则是自由模式，不会自动贴合滑动位置
                autoplay: false,//切换时间间隔
                //loop:true,//循环
                observer: true,//修改swiper自己或子元素时，自动初始化swiper
                observeParents: true,//修改swiper的父元素时，自动初始化swiper 
                spaceBetween: 4,//slide之间的距离，px
                slidesPerView: 8,//slide可见数量
                mousewheelControl: true,//鼠标滚轮控制
                grabCursor: false,//鼠标变为抓手
                preventClicksPropagation: false,
                // 其他配置项...
                navigation: {
                    nextEl: '#button-next',
                    prevEl: '#button-prev',
                },

            },
            spShow: false//默认为ture将不会显示自定义内容
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
    watch: {
        swiperVerticalData: {
            handler() {
                if (this.$refs.mySwiper) {
                    this.$refs.mySwiper.swiper.update(true)//更新
                }
            },
            immediate: true
        },
        'swiperVerticalData.length': {
            handler(newValue, oldValue) {
                // 有数据显示swiper，没数据显示自定义内容
                if (newValue > 0) {
                    this.spShow = true
                } else {
                    this.spShow = false
                }
                if (this.$refs.mySwiper) {
                    this.$refs.mySwiper.swiper.update(true)
                }
            },
            immediate: true
        }

    },
    methods: {
        mouseEnterHandler() {
            if (this.$refs.mySwiper) {//鼠标移入停止滚动
                this.$refs.mySwiper.swiper.stopAutoplay()
            }

        },
        mouseLeaveHandler() {
            if (this.$refs.mySwiper) {//鼠标移出开始滚动
                this.$refs.mySwiper.swiper.startAutoplay()
            }


        },

    },
}

</script>

<style lang="scss" scoped>
:deep(.swiper-wrapper) {
    height: 400px !important;
}

.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: 96%;
    /* 左右按钮在底部 */
}

.swiper-button-next:after,
.swiper-button-prev:after {
    transform: rotate(270deg);
    font-size: 24px;
    color: #941D1E;
}

.swiper-button-prev {
    left: 60px
}

.swiper-button-next {
    right: 60px;
}

.slide-box {
    width: 100%;
    height: 100%;
    color: #fff;
    position: relative;
    overflow-y: auto;
}

.swiper-container {
    height: 96%;
}

.swiperSlide {
    height: 33px;
    width: 96%;
    background: rgba(35, 37, 165, 0.2);
    border-left: 3px solid rgb(199, 218, 35);
    border-right: 3px solid #efbc4a;
    font-size: 13px;
    color: rgb(245, 245, 245);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: #020c3b 1px 2px 2px;
}

.boxzb-right-list {
    width: 90%;
    height: 42px !important;
    border-bottom: 1px solid #F4F4F4;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content {
        font-family: PingFang TC, PingFang TC;
        font-weight: 400;
        font-size: 14px;
        color: #0F0E0F;
        font-style: normal;
        text-transform: none;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        width: 85%;
    }

    .time {
        font-family: PingFang TC, PingFang TC;
        font-weight: 400;
        font-size: 14px;
        color: #8D8F94;
        font-style: normal;
        text-transform: none;
    }
}

.swiperPagination {
    height: 10%;
    text-align: center;
}

.noErrorStyle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.boxzb-right-btn {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0;
    background-color: #ffffff;
    z-index: 10;

    .line {
        position: absolute;
        bottom: 12px;
        height: 20px;
        margin-top: 10px;
        border: 1px solid #EEEEEE;
        left: 50%;
    }
}
</style>